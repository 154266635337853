<template>
  <v-container>
    <v-card class="elevation-2  mx-auto" :loading="loading" max-width="600">
      <v-card-title>Settings</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <form>
          <v-text-field v-model="form_data.cc" :error-messages="form_error.cc" label="CC" outlined dense></v-text-field>
          <v-text-field v-model="form_data.bcc" :error-messages="form_error.bcc" label="BCC" outlined dense></v-text-field>
          <v-btn class="mr-4" color="secondary" @click="updateSetting">Update</v-btn>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "Setting",
  data() {
    return {
      form_error: {},
      form_data: {},
      loading: false
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    updateSetting() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.post(`admin/setting/edit`, this.form_data)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getSettingDetails() {
      let _self = this;
      this.loading = "secondary";
      this.$axios.get(`admin/setting/get`)
        .then((response) => {
          console.log(response)
          _self.form_data = response.data.data
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    }
  },
  mounted: function () {
    this.getSettingDetails();
  },
};
</script>